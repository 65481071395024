<template>
  <app-form
    :value="value"
    @submit="createApp"
  />
</template>

<script>
import AppForm from '@/components/apps/AppForm.vue'
export default {
  name: 'CreateApp',
  components: {
    'app-form': AppForm,
  },
  data () {
    return {
      value: {
        name: '',
        image: '',
        category: '',
        url: '',
        info: '',
      },
    }
  },
  methods: {
    createApp () {
      this.isSubmitting = true
      this.$store
        .dispatch('app/createApp', this.value)
        .then(() => {
          this.$router.push({ name: 'apps' })
          this.clearState()
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
  },
  watch: {
    '$route.meta' ({ showModal }) {
      this.showModal = showModal
    },
  },
}
</script>
<style>
.img {
  height: 300px;
}
.bg-white {
  background: white;
}
</style>
