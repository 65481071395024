<template>
  <v-form
    ref="form"
    @submit.prevent="createApp"
  >
    <v-row class="ma-5">
      <v-col>
        <s-text-field
          name="name"
          label="Name"
          type="text"
          filled
          required
          v-model="value.name"
          :error-messages="getErrors('text', $v.value.name, 'App name', '2')"
          @blur="$v.value.name.$touch()"
        />
        <s-text-field
          name="image"
          label="Image"
          type="text"
          filled
          required
          v-model="value.image"
          :error-messages="getErrors('text', $v.value.image, 'Image')"
          @blur="$v.value.image.$touch()"
        />
        <s-select
          label="Category"
          name="category"
          filled
          required
          :items="categories"
          v-model="value.category"
          :error-messages="getErrors('text', $v.value.category, 'Category')"
          @blur="$v.value.category.$touch()"
        />
        <s-text-field
          name="url"
          label="URL"
          type="text"
          filled
          required
          v-model="value.url"
          :error-messages="getErrors('url', $v.value.url, 'URL')"
          @blur="$v.value.url.$touch()"
        />
        <s-text-field
          name="info"
          label="App Info"
          type="text"
          class="mb-n1"
          filled
          required
          v-model="value.info"
          :error-messages="getErrors('text', $v.value.info, 'Info')"
          @blur="$v.value.info.$touch()"
        />
        <div class="d-flex mt-4">
          <s-btn
            class="mr-1"
            :loading="isSubmitting"
            type="submit"
            color="primary"
            elevation="0"
          >
            Submit
          </s-btn>
          <s-btn
            class="delete-btn ml-2"
            elevation="0"
            @click="clearState"
            text
          >
            <v-icon>mdi-close</v-icon>
            Cancel
          </s-btn>
        </div>
      </v-col>
    </v-row>
  </v-form>
</template>
<script>
import { vuelidateErrors } from '@/mixin'
import { required, url, minLength } from 'vuelidate/lib/validators'
export default {
  name: 'AppForm',
  mixins: [vuelidateErrors],
  props: {
    value: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'app',
    },
  },
  data () {
    return {
      isSubmitting: false,
      categories: ['TCP', 'UDP'],
    }
  },
  validations: {
    value: {
      name: {
        required,
        minLength: minLength(2),
      },
      image: { required },
      category: { required },
      url: { required, url },
      info: { required },
    },
  },
  methods: {
    clearState () {
      // TODO
      // Add confirmation to discard data provided
      this.closeCreateApp()
    },
    closeCreateApp () {
      this.$router.push({ name: 'apps' })
    },
    createApp () {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.$emit('submit')
    },
  },
}
</script>
<style scoped>
.count-box {
  background: #dfe2e6;
  border: 1px solid #dfe2e6;
  box-sizing: border-box;
  border-radius: 6px;
  width: 26px;
  height: 23px;
  color: #5599ff;
  font-size: 14px;
}
.bg-white {
  background: white;
}

.delete-btn {
  color: #EC3944;
  background-color: #fff !important;
}
</style>
